import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    permissions: []
};


export const permissionSlice = createSlice({
	name: 'Permission',
	initialState,
	reducers: {
        setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
        resetPermission: (state) => {
            return initialState;
        }
	},
});


export const { 
    setPermissions,
    resetPermission
} = permissionSlice.actions;


export default permissionSlice.reducer;