// 1. Error Messages
export const GENERAL_ERROR_MESSAGE = "Një gabim ndodhi gjatë komunikimit me serverin!"
export const DATA_FILLED_ERROR_MESSAGE = "Ju lutem, plotësoni fushat që mungojnë!"
export const ALLOW_IMAGE_ERROR_MESSAGE = "Imazhi duhet të jetë në format JPEG/PNG!"
export const SIZE_IMAGE_ERROR_MESSAGE = "Imazhi duhet të jetë më i vogël se 2MB!"
export const ALLOW_PDF_ERROR_MESSAGE = "Dokumenti i ngarkuar duhet të jetë në format PDF!"
export const ALLOW_ID_CARD_ERROR_MESSAGE = "Letërnjoftimi duhet të jetë imazh JPEG/PNG ose dokument PDF!"
export const MISSING_LOCAL_ELECTION_ERROR_MESSAGE = "Momentalisht nuk kemi zgjedhje lokale aktive!"
export const RETURN_SUCCESS_MESSAGE = "Kthimi u krye me sukses!"
export const MISSING_PARLIAMENTARY_ELECTION_MESSAGE = "Nuk ka zgjedhje për kuvend në proces!"
export const MISSING_LOCAL_ELECTION_MESSAGE = "Nuk ka zgjedhje për kuvend në proces!"


// 2. Success Messages
export const REGISTERED_SUCCESS_MESSAGE = "Regjistrimi u krye me sukses!"
export const UPDATED_SUCCESS_MESSAGE = "Modifikimi u krye me sukses!"
export const DELETED_SUCCESS_MESSAGE = "Fshirja u krye me sukses!" 
export const APPROVE_SUCCESS_MESSAGE = "Aprovimi u krye me sukses!"
export const REFUSE_SUCCESS_MESSAGE = "Refuzimi u krye me sukses!"
export const PASSWORD_RECOVERY = "Kodi u dërgua në email-in tuaj!"