import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/ThemeSlice';
import { useDispatch } from 'react-redux';


const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		const isBlank = blankLayout ? true : false;
		dispatch(onBlankLayout(isBlank));

        // const urlSearchParams = new URLSearchParams(location.search);
        // const langParameter = urlSearchParams.get('lang');
        // if (langParameter === 'en') {
        //     dispatch(onLocaleChange('en'));
        //     navigate(UNAUTHENTICATED_ENTRY);
        // } 
        
	}, [blankLayout, dispatch])
	
	return (
		<Component {...props} />
	)
}

export default AppRoute