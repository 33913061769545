// 1. BACKEND BASE URL
//export const BACKEND_BASE_URL = "http://192.168.1.140:3002";
// export const BACKEND_BASE_URL = "http://20.199.13.222:3001";
export const BACKEND_BASE_URL = "https://api.kqz.al";


// 1. DASHBOARD
export const DASHBOARD_COUNTERS = "/api/dashboard/counters/";


// 2. AUTHENTICATION
export const SIGN_IN = "/api/hr/login/";
export const SIGN_OUT = "/api/hr/logout/";


// 3. APPLICATION
export const REQUEST_OPERATOR_VALIDATION = "/api/recruitment/applications/portal/technical-operator/validate-data";
export const REQUEST_PHONE_VERIFICATION = "/api/confirmation/request-phone-confirmation/";
export const PART_TIME_ANNOUNCEMENTS = "/api/recruitment/positions/portal/open-announcements/part-time/list/";
export const REGISTER_PART_TIME_APPLICATION = "/api/recruitment/applications/portal/part-time/register/";
export const CONTRACT_SERVICE_ANNOUNCEMENTS = "/api/recruitment/positions/portal/open-announcements/service-contract/list/";
export const REGISTER_CONTRACT_SERVICE_APPLICATION = "/api/recruitment/applications/portal/service-contract/register/";
export const TECHNICAL_OPERATOR_ANNOUNCEMENTS = "/api/recruitment/positions/portal/open-announcements/technical-operator/list/";
export const REGISTER_TECHNICAL_OPERATOR_APPLICATION = "/api/recruitment/applications/portal/technical-operator/register/";


// 4. UTILITY
export const ELECTION_PROCESS_TYPES = "/api/recruitment/process-types/"
export const REGIONS = "/api/region/"
export const MUNCIPALITIES = "/api/municipality/"
export const UNITS = "/api/unit/"
export const UNITS_QVS = "/api/qv/qvs-by-units/"