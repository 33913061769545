import axios from 'axios';
import * as url from "configs/BackendConfig";


const AuthService = {}


AuthService.signIn = async (loginData) => {
    const data = {
        username: loginData.email,
        password: loginData.password
    };

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const requestUrl = url.BACKEND_BASE_URL + url.SIGN_IN;
    return await axios.post(requestUrl, data, config);
};


AuthService.completeSignIn = async (data) => {
    const config = {
        headers: {
            'Content-Type': "application/json",
        },
    };

    const requestUrl = url.BACKEND_BASE_URL + url.SIGN_IN;
    return await axios.post(requestUrl, data, config);
};


AuthService.signOut = async (token) => {
    const tokenHeader = token;
    const config = {
        headers: {
            'Content-Type': "application/json",
            'Authorization': tokenHeader
        },
    };

    const requestUrl = url.BACKEND_BASE_URL + url.SIGN_OUT;
    return axios.post(requestUrl, {}, config);
};


export default AuthService;