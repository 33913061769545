import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'services/AuthService';
import { setActiveModule, setMemberInfo } from './MemberSlice';
import { getApiResponseMessage } from 'helpers/ApiResponseHelper';
import { fullResetPerformed } from './ResetAllSlices';
import { setUrlFile } from 'helpers/FormHelper';


const formatMemberData = (data) => {
    return {
        ...data,
        fullName: data?.name + " " + data?.surname,
        email: data?.email,
        photo: setUrlFile(data?.photo),
        hasTemporaryPassword: false,
    };
};


export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: null,
    twoFactorEnabled: null,
    token2FA: null,
};


export const signIn = createAsyncThunk('auth/signIn', async (data, { dispatch, rejectWithValue }) => {
    try {
        const apiResponse = (await AuthService.signIn(data)).data;
        if (apiResponse.success === true) {
            let token = null;
            const twoFactorEnabled = apiResponse?.result?.twoFactorEnabled;
            const token2FA = apiResponse?.result?.token2FA ?? null;
            
            if (twoFactorEnabled === true) {
				const authData = {
					token: null,
                    twoFactorEnabled: twoFactorEnabled,
                    token2FA: token2FA
                };
                dispatch(authenticated(authData));
            } else {
                const { accessToken, ...memberData } =  apiResponse?.result?.userData;
                token = accessToken;
				const authData = { token: accessToken, twoFactorEnabled: null, token2FA: null }
                dispatch(authenticated(authData));
                const formattedMemberData = formatMemberData(memberData);
                dispatch(setMemberInfo(formattedMemberData));
            }

            return token;
        } else {
            const errorMessage = getApiResponseMessage(apiResponse);
            return rejectWithValue(errorMessage);
        }
    } catch (err) {
        const errorMessage = "Gabim në komunikim me serverin!";
        return rejectWithValue(errorMessage)
    }
});


export const signOut = createAsyncThunk('auth/signOut', async ({ dispatch }) => {
    dispatch(fullResetPerformed());
	dispatch(setActiveModule(null));
	dispatch(setMemberInfo(null));
	resetAuth();
    return null;
});


export const AuthSlice = createSlice({
	name: 'Auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload.token
            state.twoFactorEnabled = action.payload.twoFactorEnabled
            state.token2FA = action.payload.token2FA
		},
        twoAuthenticated: (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.token = null
            state.twoFactorEnabled = action.payload.twoFactorEnabled
            state.token2FA = action.payload.token2FA
        },
        resetTwoFactorEnabled: (state, action) => {
            state.twoFactorEnabled = null
            state.token2FA = null
        },
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		},
		resetLoading: (state) => {
			state.loading = false
		},
        resetAuth: (state) => {
            return initialState;
        }
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
	},
})

export const { 
	authenticated,
    twoAuthenticated,
	resetTwoFactorEnabled,
	resetLoading,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
    resetAuth
} = AuthSlice.actions

export default AuthSlice.reducer