import { BACKEND_BASE_URL } from "configs/BackendConfig";
import dayjs from "dayjs";



export const setFormFieldValue = (givenValue) => {
    if (isValidValue(givenValue) === false) {
        return null;
    }
    return givenValue;
};


export const setResponsible = (kzaz) => {
    return kzaz?.kzazResponsible ? kzaz?.kzazResponsible?.name : "Regjistrimi shqyrtohet nga KQZ!";
};


export const setDateFieldValue = (givenDate, dateFormat) => {
    if (isValidValue(givenDate) === false) {
        return null;
    }

    const formattedDate = dayjs(givenDate, dateFormat);
    return formattedDate;
};


export const setDateValue = (givenDate, dateFormat) => {
    if (isValidValue(givenDate) === false) {
        return null;
    }

    const formattedDate = dayjs(givenDate).format(dateFormat);
    return formattedDate;
};


export const formatIntegerId = (givenId) => {
    if (isValidValue(givenId) === false) {
        return null;
    }

    return parseInt(givenId);
};


export const isValidValue = (value) => {
    if (value === null || value === undefined || value === "" || value === "null" || value === "undefined") {
        return false;
    }
    return true;
};


export const setDisabledDates = (currentDate, givenDate) => {
    const formattedDate = dayjs(givenDate).format("YYYY-MM-DD");
    return currentDate && currentDate < dayjs(formattedDate, "YYYY-MM-DD");
};


export const setCandidateGender = (genderText) => {
    if (genderText === "M") {
        return 1;
    }

    if (genderText === "F") {
        return 2;
    }

    return null;
};


export const setDate = (date) => {
    if (date) {
        return dayjs(date, "YYYY-MM-DD");
    }

    return null;
};


export const setUrlFile = (file) => {
    if (isValidValue(file) === false) {
        return null;
    }

    return BACKEND_BASE_URL + file;
};


export const splitPhonePrefix = (value) => {
    if (isValidValue(value) === false) {
        return null;
    }

    if (value?.toString()?.includes("+355")) {
        return value?.toString()?.split("+355")[1];
    }

    return value;
};