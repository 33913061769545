import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';

const PublicRoute = () => {
    const { token } = useSelector(state => state.Auth);

    // const redirectToMainURL = () => {
    //     console.log('MAIN LOCATION: ', window.location);
    //     const { search } = window.location;
    //     const mainURL = search ? `/?${search.slice(1)}` : '/';
    //     navigate(mainURL);
    // };

    return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />
}

export default PublicRoute