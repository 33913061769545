import React from 'react';
import { Provider } from 'react-redux'; // Check this import
import { HashRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { pdfjs } from 'react-pdf';


const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function App() {

    return (
        <div className="App">
            <Provider store={store}>
                <HashRouter history={history}>
                    <ThemeSwitcherProvider
                        themeMap={themes}
                        defaultTheme={THEME_CONFIG.currentTheme}
                        insertionPoint="styles-insertion-point"
                    >
                        <Layouts />
                    </ThemeSwitcherProvider>
                </HashRouter>
            </Provider>
        </div>
    );
}

export default App;
