import React from 'react'
import { APP_PREFIX_PATH } from 'configs/AppConfig'


export const publicRoutes = [
    {
        key: 'application.main',
        path: `/application/main`,
        component: React.lazy(() => import('views/application-views/main')),
    },
    {
        key: 'application.rcruitment',
        path: `/application/recruitment`,
        component: React.lazy(() => import('views/auth-views/recruitment')),
    },
];



export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboard')),
    },
];
