import { createSlice } from '@reduxjs/toolkit';
import { resetMember } from './MemberSlice';
import { resetPermission } from './PermissionSlice';
import store from 'store';
import { resetAuth } from './AuthSlice';


const initialState = {
    fullResetPerformed: false,
};


const ResetAllSlicesSlice = createSlice({
    name: 'ResetAllSlices',
    initialState,
    reducers: {
        fullResetPerformed: (state) => {
            store.dispatch(resetAuth());
            store.dispatch(resetMember());
            store.dispatch(resetPermission());
            return { ...initialState, fullResetPerformed: true };
        },
    },
});


export const { fullResetPerformed } = ResetAllSlicesSlice.actions;
export default ResetAllSlicesSlice.reducer;