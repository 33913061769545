import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    memberInfo: null,
    partyInfo: null,
    activeModule: null,
    hasTemporaryPassword: null,
    refreshIndex: 0,
    systemVersion: null
};


export const MemberSlice = createSlice({
	name: 'Member',
	initialState,
	reducers: {
        setMemberInfo: (state, action) => {
			state.memberInfo = action.payload;
		},
        setPartyInfo: (state, action) => {
            state.partyInfo = action.payload;
        },
        setActiveModule: (state, action) => {
            state.activeModule = action.payload;
        },
        setSystemVersion: (state, action) => {
			state.systemVersion = action.payload;
		},
        resetMemberInfo: (state, action) => {
			state.memberInfo = null;
		},
        setRefreshIndex: (state, action) => {
			state.refreshIndex = state.refreshIndex + 1;
		},
        updateProfile: (state, action) => {
			state.memberInfo.avatar = action.payload.avatar;
            state.refreshIndex = state.refreshIndex + 1;
		},
        updateTemporaryPassword: (state, action) => {
            state.hasTemporaryPassword = action.payload;
            state.refreshIndex = state.refreshIndex + 1;
        },
        resetMember: (state) => {
            return initialState;
        }
	},
});


export const { 
    setMemberInfo,
    setPartyInfo,
    setActiveModule,
    setSystemVersion,
    resetMemberInfo,
    setRefreshIndex,
    updateProfile,
    updateTemporaryPassword,
    resetMember
} = MemberSlice.actions;


export default MemberSlice.reducer;