import { combineReducers } from 'redux';
import Theme from './slices/ThemeSlice';
import Auth from './slices/AuthSlice';
import Member from './slices/MemberSlice';
import Permission from './slices/PermissionSlice';

const rootReducer = combineReducers({
    Theme: Theme,
    Auth: Auth,
    Member: Member,
    Permission: Permission,
});

export default rootReducer;
