import { AUTHENTICATION_LOGIN } from "configs/AppConfig";
import { GENERAL_ERROR_MESSAGE } from "constants/MessageConstant";
import { resetAuth } from "store/slices/AuthSlice";
import { resetMember } from "store/slices/MemberSlice";



export const getErrorMessage = (error) => {
    if (error?.response?.data?.Error) {
        return error.response.data.Error;
    }

    return GENERAL_ERROR_MESSAGE;
};


export const validateExpiredAuthentication = (apiResponse, dispatchRedux, navigate) => {
    const apiResponseStatus = apiResponse?.status;
    const hasAuthenticationExpired = apiResponseStatus === 401;
    if (hasAuthenticationExpired === true) {
        dispatchRedux(resetMember());
        dispatchRedux(resetAuth());
        navigate(AUTHENTICATION_LOGIN);
    }
};


export const getApiResponseData = (apiResponse) => {
    let data = null;

    if (apiResponse.success === true) {
        data = apiResponse.result;
    }

    return data;
}


export const getApiResponseMessage = (apiResponse) => {
    let errorMessage = GENERAL_ERROR_MESSAGE;

    if (apiResponse.success === false) {
        const message = apiResponse?.message ?? null;
        if (message) {
            errorMessage = message;
            return errorMessage;
        }

        const internalMessages = apiResponse?.internal_message
        if (internalMessages) {
            errorMessage = internalMessages?.validation_messages?.map((elm) => (
                elm?.toString() + '\n'
            ));
        }
    }

    return errorMessage;
};


export const getDetailedApiResponseMessage = (apiResponse) => {
    const errorMessage = {
        main: GENERAL_ERROR_MESSAGE,
        extra: null
    };

    if (apiResponse.success === false) {
        const validationResponse = apiResponse?.validationResponse;
        const validationMessages = validationResponse?.validationMessages;
        if (validationMessages) {
            if (validationMessages?.length > 0) {
                const validationErrorMessage = validationMessages[0];
                return {
                    main: apiResponse?.message ?? "Të dhënat nuk janë në formatin e duhur!",
                    extra: validationErrorMessage
                };
            }
        }

        const internalMessages = apiResponse?.internalMessage;
        if (internalMessages) {
            if (internalMessages?.length > 0) {
                const internalErrorMessage = internalMessages[0];
                return {
                    main: apiResponse?.message ?? "Të dhënat nuk janë në formatin e duhur!",
                    extra: internalErrorMessage
                };
            }
        }

        const message = apiResponse?.message ?? null;
        if (message) {
            return {
                main: message,
                extra: null
            };
        }
    }

    return errorMessage;
};