import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { UNAUTHENTICATED_ENTRY } from 'configs/AppConfig';


const ProtectedRoute = () => {
    const { token } = useSelector((state) => state.Auth);

    if (!token) {
        return <Navigate to={UNAUTHENTICATED_ENTRY} />
        // return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
    }

    return <Outlet />
}

export default ProtectedRoute